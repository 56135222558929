<template>
  <multiselect
    v-model="selectedValue"
    :options="currentOptions"
    :searchable="true"
    :close-on-select="true"
    :show-labels="false"
    :allow-empty="allowEmpty"
    :placeholder="placeholder"
    :internal-search="false"
    :multiple="multiple"
    :label="label"
    :track-by="trackBy"
    @search-change="handleSearch"
    @update:modelValue="handleChange">
  </multiselect>
</template>

<script>
export default {
  name: 'CustomSelect',
  props: {
    modelValue: {
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    trackBy: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      originalOptions: [],
      currentOptions: [],
      selectedValue: this.modelValue
    }
  },
  watch: {
    options: {
      immediate: true,
      handler(newVal) {
        this.originalOptions = [...newVal];
        this.currentOptions = [...newVal];
      }
    },
    modelValue(newVal) {
      this.selectedValue = newVal;
    }
  },
  methods: {
    handleSearch(search) {
      if (!search) {
        this.currentOptions = [...this.originalOptions];
        return;
      }
      
      if (this.trackBy && this.label) {
        this.currentOptions = this.originalOptions.filter(
          item => item[this.label].toLowerCase().startsWith(search.toLowerCase()) 
        );
      } else {
        this.currentOptions = this.originalOptions.filter(
          item => item.toLowerCase().startsWith(search.toLowerCase())
        );
      }
    },
    handleChange(val) {
      this.$emit('update:modelValue', val);
      this.$emit('change', val, this.field );
    }
  }
}
</script>