<template>
  <Breadcrumbs title="Role Setting Detail"/>
  <div class="container-fluid">
    <div class="d-flex align-items-center gap-1 mb-4">
      <router-link :to="{ name: 'roleSetting', query: this.$route.query }" class="link-dark">
        <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
      </router-link>
      <h5 class="mb-0">{{ roleName }}</h5>
    </div>
    <div class="row g-3">
      <div class="col-md-6">
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Account ID</th>
                  <th scope="col">Account</th>
                  <th scope="col" @click="sortList('status')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Active</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('created_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Created at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="list.length > 0" >
                <tr v-for="item in list" :key="item.id">
                  <td>#{{ item.id }}</td>
                  <td class="d-flex align-items-center gap-2">
                    <img class="img-40 rounded-circle" :src="item.profile ? item.profile : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                    <div>
                      <p class="mb-0 text-dark">{{ item.first_name }} {{ item.last_name }}</p>
                      <p class="mb-0">{{ item.email }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex gap-2 align-items-center">
                      <label :class="['form-check-label', 'mb-0', { 'txt-primary': item.status }]">{{ item.status ? 'Enabled' : 'Disabled' }}</label>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="item.status" :true-value="1" :false-value="0" 
                        @click.prevent="confirmSwitch(item)">
                      </div>
                    </div>
                  </td>
                  <td>{{ item.created_at }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4" class="text-center">
                    {{ listRequest.search ? 'No matching records found' : 'No data available in table' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h6 class="text-secondary mb-0">Permissions</h6>
              <div class="fw-semibold">
                <vue-feather type="edit" size="16" class="me-2 text-primary"></vue-feather>
                <a href="javascript:;" class="text-primary" @click.prevent="showModal('edit')">Edit</a>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="d-flex gap-2 align-items-center">
                <label :class="['form-check-label', 'mb-0', permissions.status ? 'txt-primary' : 'text-secondary']">{{ permissions.status ? 'Enabled' : 'Disabled' }}</label>
                <div class="form-check form-switch">
                  <input v-model="permissions.status" :true-value="1" :false-value="0" class="form-check-input" type="checkbox" role="switch" disabled>
                </div>
              </div>
            </div>
            <div v-if="permissions.permissions" class="ms-3 mb-3">
              <div v-for="permission in permissions.permissions" :key="permission.id" class="checkbox checkbox-primary">
                <input :id="permission.name" type="checkbox" :checked="permission.enabled" disabled>
                <label :for="permission.name">{{ permission.name }}</label>
                <div v-if="permission.children.length" class="submenu-box position-relative">
                  <div v-for="child in permission.children" :key="child.id" class="checkbox checkbox-primary">
                    <input :id="child.name" type="checkbox" :checked="child.enabled" disabled>
                    <label :for="child.name">{{ child.name }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <p class="fs-6 mb-0">Created at</p>
              <p class="text-secondary mb-0">{{ permissions.created_at }}</p>
            </div>
            <div class="mb-3">
              <p class="fs-6 mb-0">Update at</p>
              <p class="text-secondary mb-0">{{ permissions.updated_at }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <edit ref="edit" v-if="shown.edit" :data="permissions" @hide="hideModal('edit')" @update="getPermissions"></edit>
  <switchCheck v-if="shown.switchCheck" ref="switchCheck" :text="text" @handleSwitch="switchStatus" @hide="hideModal('switchCheck')" />
</template>

<script>
import Pagination from '@/components-gc/Pagination.vue';
import { mapState } from 'vuex';
import { checkStatusCode } from '@/methods-gc/statusCode';
import Modal from "bootstrap/js/dist/modal";
import edit from '@/components-gc/modal/setting/roleSetting/edit.vue';
import switchCheck from '@/components-gc/modal/switchCheck.vue';


export default {
  data() {
    return {
      list: [],
      listRequest: {
        role_id: this.$route.params.roleId,
        enabled: '',
        page: 1,
        limit: 10,
        order_by: "",
        order: "",
        search: '',
      },
      total: 0,
      itemStatus: 'enabled',
      permissions: {},
      shown: {
        edit: false,
        switchCheck: false,
      },
      modals: {},
      switchItem: null,
    };
  },
  computed: {
        ...mapState('user', ['roleName']),
    },
  components: {
    edit,
    switchCheck,
  },
  mounted() {
    this.getList();
    this.getPermissions();
  },
  methods: {
    getList() {
      const api = `${process.env.VUE_APP_PATH}/administrator/list`;
      this.axios.post(api, this.listRequest)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.list = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    confirmSwitch(item) {
      this.switchItem = item;
      this.showModal('switchCheck');
    },
    switchStatus() {
      this.hideModal('switchCheck');
      const api = `${process.env.VUE_APP_PATH}/administrator/switch`;
      this.axios.post(api, {
        admin_id: this.switchItem.id,
        enable: !this.switchItem.status
      })
      .then((res) => {
        if (res.data.status_code !== 'SYSTEM_1000') {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        } else {
          this.getList();
          this.$toastSuccess('Status updated successfully');
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getPermissions() {
      const api = `${process.env.VUE_APP_PATH}/role/permission`;
      this.axios.post(api, { role_id: this.$route.params.roleId })
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.permissions = res.data;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        if (this.$refs[name]) {
          this.modals[name] = new Modal(this.$refs[name].$el);
          this.modals[name].show();
          this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
        }
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if (this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
  },
  updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
};
</script>

<style lang="scss" scoped>
.submenu-box {
  margin-left: 6px;
}
.submenu-box::before {
  content: '';
  width: 1px;
  height: calc(100% - 17px);
  background-color: var(--bs-gray-500);
  position: absolute;
  left: 0;
  top: 0;
}
.submenu-box .checkbox::before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 1px;
  background-color: var(--bs-gray-500);
  margin-right: 15px;
  vertical-align: middle;
}
</style>