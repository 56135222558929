<template>
  <div class="modal fade" id="editUserProfileModal" tabindex="-1" aria-labelledby="editUserProfileLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit User Profile</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row g-3">
                <div class="col-12 col-md-3 mb-md-3">
                    <label class="col-form-label fw-semibold">Profile Image</label>
                    <img class="w-100 img-fluid" :src="localUserData.profile ? localUserData.profile : 'https://fakeimg.pl/500x500'" alt="profile">
                </div>
                <div class="col-12 col-md-9 mb-md-3">
                  <div class="row">
                    <div class="col-12 col-md-6 mb-md-5">
                      <label for="firstName" class="col-form-label fw-semibold">First Name</label>
                      <div class="position-relative">
                        <Field name="firstName" type="text" class="form-control" placeholder="First Name" id="firstName" v-model="localUserData.first_name" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 mb-md-5">
                      <label for="lastName" class="col-form-label fw-semibold">Last Name</label>
                      <div class="position-relative">
                        <Field name="lastName" type="text" class="form-control" placeholder="Last Name" id="lastName" v-model="localUserData.last_name" />
                      </div>
                    </div>
                    <div class="col-12 col-md-4 mb-md-3">
                      <label class="col-form-label fw-semibold">Account</label>
                      <p class="mb-0 text-secondary">{{ localUserData.code }} {{ localUserData.phone }}</p>
                    </div>
                    <div class="col-12 col-md-4 mb-md-3">
                      <label class="col-form-label fw-semibold">User ID</label>
                      <p class="mb-0 text-secondary">{{ localUserData.user_id }}</p>
                    </div>
                    <div class="col-12 col-md-4 mb-md-3">
                      <label class="col-form-label fw-semibold">Created at</label>
                      <p class="mb-0 text-secondary">{{ localUserData.created_at }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3 mb-md-3">
                  <label for="mail" class="col-form-label fw-semibold">Mail Address</label>
                  <div class="position-relative">
                    <Field name="mail" type="email" class="form-control" placeholder="Mail Address" id="mail" v-model="localUserData.email" />
                  </div>
                </div>
                <div class="col-12 col-md-3 mb-md-3">
                  <label for="phone" class="col-form-label fw-semibold">Local Phone</label>
                    <div class="position-relative">
                      <Field name="phone" type="tel" class="form-control" placeholder="Local Phone" id="phone" v-model="localUserData.local_phone" />
                    </div>
                </div>
                <div class="col-12 col-md-3 mb-md-3">
                  <label for="postcode" class="col-form-label fw-semibold">Postcode</label>
                  <div class="position-relative">
                    <Field name="postcode" type="text" class="form-control" placeholder="Postcode" id="postcode" v-model="localUserData.postcode" />
                  </div>
                </div>
                <div class="col-12 col-md-3 mb-md-3">
                  <label class="col-form-label fw-semibold">Country</label>
                  <Field name="country" v-slot="{ field }">
                    <CustomSelect
                      v-model="localUserData.country" 
                      :options="options.country" 
                      :field="'country'"
                      @change="selectChange"
                      placeholder="Country" 
                      v-bind="field"
                    />
                  </Field>
                </div>
                <div class="col-12 col-md-3 mb-md-3">
                  <label class="col-form-label fw-semibold">State</label>
                  <Field name="state" v-slot="{ field }">
                    <CustomSelect
                      v-model="localUserData.state" 
                      :options="options.state"
                      :field="'state'"
                      @change="selectChange"
                      placeholder="State" 
                      v-bind="field"
                    />
                  </Field>
                </div>
                <div class="col-12 col-md-3 mb-md-3">
                    <label class="col-form-label fw-semibold">City</label>
                    <Field name="city" v-slot="{ field }">
                      <CustomSelect
                        v-model="localUserData.city" 
                        :options="options.city"
                        :field="'city'"
                        @change="selectChange"
                        placeholder="City" 
                        v-bind="field"
                      />
                    </Field>
                </div>
                <div class="col-12 col-md-6 mb-md-3">
                  <label for="streetAddress" class="col-form-label fw-semibold">Street Address</label>
                  <div class="position-relative">
                    <Field name="streetAddress" type="text" class="form-control" placeholder="Street Address" id="streetAddress" v-model="localUserData.address" />
                  </div>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '../../../methods-gc/statusCode';
import CustomSelect from '../../CustomSelect.vue';

export default {
  data() {
    return {
      localUserData: { ...this.userData },
      options: {
          country: [],
          state: [],
          city: []
        },
    }
  },
  props: ['userData'],
  emits: ['hide', 'getUserData'],
  components: {
    Form,
    Field,
    ErrorMessage,
    CustomSelect,
  },
  mounted() {
    this.getCountry();
    if(this.localUserData.country) {
      this.getState();
    }
    if(this.localUserData.state) {
      this.getCity();
    }
  },
  methods: {
    getCountry() {
      const api = `${process.env.VUE_APP_PATH}/global/countries`;
      this.axios.get(api, {
        params: { type: 'global' }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.country = res.data.countries;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getState() {
      const api = `${process.env.VUE_APP_PATH}/global/states`;
      this.axios.get(api, {
        params: { type: 'global', country: this.localUserData.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.state = res.data.states;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getCity() {
      this.localUserData.city = null;
      const api = `${process.env.VUE_APP_PATH}/global/cities`;
      this.axios.get(api, {
        params: { type: 'global', country: this.localUserData.country, state: this.localUserData.state }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.city = res.data.cities;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    selectChange(val,field) {
      if(field === 'country') {
        this.localUserData.state = null;
        this.localUserData.city = null;
        this.options.state = [];
        this.options.city = [];
        if(val) {
          this.getState();
        }
      }else if(field === 'state') {
        this.localUserData.city = null;
        this.options.city = [];
        if(val) {
          this.getCity();
        }
      }
    },
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/user/update`;
      const payload = {
        user_id: this.localUserData.user_id,
        first_name: this.localUserData.first_name,
        last_name: this.localUserData.last_name,
        country: this.localUserData.country,
        postcode: this.localUserData.postcode,
        city: this.localUserData.city,
        state: this.localUserData.state,
        address: this.localUserData.address,
        home_phone: this.localUserData.local_phone,
        email: this.localUserData.email
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('getUserData');
          this.$emit('hide');
          this.$toastSuccess('User profile updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
}
</script>