export const transformMixin = {
  methods: {
    statusClass(status) {
      switch (status) {
        case 'good':
          return { 'txt-primary': true };
        case 'risk':
          return { 'text-warning': true };
        case 'critical':
          return { 'txt-secondary': true };
        default:
          return { 'text-dark': true };
      }
    },
    statusText(status) {
      switch (status) {
        case 'good':
          return 'All Good';
        case 'risk':
          return 'At Risk';
        case 'critical':
          return 'Critical';
        default:
          return '';
      }
    }
  }
};