<template>
  <div class="modal fade" id="settingModal" tabindex="-1" aria-labelledby="settingLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <Form >
          <div class="modal-header">
            <h1 class="modal-title fs-5">Setting</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt-0">
            <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center 
        justify-content-between mb-3 gap-3">
          <div class="d-flex gap-3 flex-column flex-md-row flex-shrink-0">
                <div v-if="localDetail.type === 'specify'" class="select-wrap flex-shrink-0 account">
                  <Field name="accountType" v-slot="{ field }">
                    <CustomSelect
                      v-model="listRequest.account_type"
                      :options="options.accountType"
                      :placeholder="'Account Type'"
                      :field="'accountType'"
                      v-bind="field"
                      @update:modelValue="accountTypeChange"
                    ></CustomSelect>
                    <ErrorMessage name="accountType" class="text-danger"></ErrorMessage>
                  </Field>
                </div>
                <div class="select-wrap flex-shrink-0">
                  <Field name="country" v-slot="{ field }">
                    <CustomSelect
                      v-model="listRequest.country"
                      :options="options.country"
                      :placeholder="'Country'"
                      :field="'country'"
                      v-bind="field"
                      @update:modelValue="val => selectChange(val, 'country')"
                    ></CustomSelect>
                    <ErrorMessage name="country" class="text-danger"></ErrorMessage>
                  </Field>
                </div>
                <div class="select-wrap flex-shrink-0">
                  <Field name="state" v-slot="{ field }">
                    <CustomSelect
                      v-model="listRequest.state"
                      :options="options.state"
                      :placeholder="'State'"
                      :field="'state'"
                      v-bind="field"
                      @update:modelValue="val => selectChange(val, 'state')"
                    ></CustomSelect>
                    <ErrorMessage name="state" class="text-danger"></ErrorMessage>
                  </Field>
                </div>
                <div class="select-wrap flex-shrink-0">
                  <Field name="city" v-slot="{ field }">
                    <CustomSelect
                      v-model="listRequest.city"
                      :options="options.city"
                      :placeholder="'City'"
                      :field="'city'"
                      v-bind="field"
                    ></CustomSelect>
                    <ErrorMessage name="city" class="text-danger"></ErrorMessage>
                  </Field>
                </div>
                <button type="button" class=" btn btn-primary" @click.prevent="handleSearch">Search</button>
                <a href="javascript:void(0)" class="align-self-center clear-btn" @click.prevent="clearAll">Clear all</a>
              </div>
          <div class="position-relative align-self-md-center search-box flex-shrink-1">
                <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
                <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
              </div>
            </div>
            <div class="table-responsive staff-wrap">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col">Account</th>
                    <th scope="col">Country</th>
                    <th scope="col">State</th>
                    <th scope="col">City</th>
                    <th  v-if="localDetail.type === 'specify'" scope="col">Account Type</th>
                    <th scope="col">
                      <div class="checkbox checkbox-primary">
                        <input id="all" type="checkbox" v-model="allChecked" @change="toggleAll($event)">
                        <label for="all" class="my-0">All</label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="staffList.length > 0">
                  <tr v-for="item in staffList" :key="item.id">
                    <td>
                      <div class="d-flex align-items-center gap-2">
                        <img class="img-40 rounded-circle" :src="item.profile ? item.profile : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                        <div>
                          <p class="mb-0 text-dark">{{ item.first_name }} {{ item.last_name }}</p>
                          <p class="mb-0">{{ item.email }}</p>
                        </div>
                      </div>
                    </td>
                    <td>{{ item.country }}</td>
                    <td>{{ item.state }}</td>
                    <td>{{ item.city }}</td>
                    <td  v-if="localDetail.type === 'specify'">{{ item.account_type }}</td>
                   <td>
                      <div class="checkbox checkbox-primary">
                        <input :id="item.id" type="checkbox" :value="item.id" v-model="selectedVals" name="assign">
                        <label :for="item.id"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">
                      {{ listRequest.search ? 'No matching records found' : 'No data available in table' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="assign">Assign</button>
          </div>
        </Form>
      </div>
    </div>
  </div>

</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Pagination from '@/components-gc/Pagination.vue';
import CustomSelect from '@/components-gc/CustomSelect.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      options: {
        country: [],
        state: [],
        city: [],
        accountType: ['user', 'staff']
      },
      staffList: [],
      listRequest: {
        account_type: "",
        country: "",
        state: "",
        city: "",
        search: "",
        page: 1,
        limit: 5,
      },
      total: null,
      localDetail: JSON.parse(JSON.stringify(this.detail)),
      selectedVals: this.detail.notify_users.map(user => user.id),
      allChecked: false,
      previousListRequest: {}
    }
  },
  props: ['detail'],
  emit: ['update'],
  mounted() {
    this.getCountry();
    this.getList();
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Pagination,
    CustomSelect,
  },
  watch: {
    'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.listRequest.page = 1;
        this.getList();
        this.allChecked = false;
      }
    },
    'staffList': {
      handler(newList) {
        // Update selectedVals based on the new staffList
        newList.forEach(item => {
          item.selected = this.selectedVals.includes(item.id);
        });
      },
      deep: true
    }
  },
  methods: {
    getCountry() {
      const api = `${process.env.VUE_APP_PATH}/global/countries`;
      const countryType = this.localDetail.type === 'specify' ? 'account' : 'staff';

      this.axios.get(api, {
        params: { type: countryType }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.country = res.data.countries;
          this.options.country.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getState() {
      const api = `${process.env.VUE_APP_PATH}/global/states`;
      const countryType = this.localDetail.type === 'specify' ? 'account' : 'staff';

      this.axios.get(api, {
        params: { type: countryType, country: this.listRequest.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.state = res.data.states;
          this.options.state.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getCity() {
      const api = `${process.env.VUE_APP_PATH}/global/cities`;
      const countryType = this.localDetail.type === 'specify' ? 'account' : 'staff';

      this.axios.get(api, {
        params: { 
          type: countryType, 
          state: this.listRequest.state,
          country: this.listRequest.country
         }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.city = res.data.cities;
          this.options.city.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getList() {
      const params = { ...this.listRequest };
      Object.keys(params).forEach(key => {
        if (params[key] === 'All') {
          params[key] = '';
        }
        if(params[key] !== null && typeof params[key] === 'string') {
          params[key] = params[key].toLowerCase();
        }
      });

      const apiType = this.localDetail.type === 'specify' ? '/notifactions/account_list' : '/staff/list';
      if (apiType === '/staff/list') {
        delete params.account_type;
      }
      
      const api = `${process.env.VUE_APP_PATH}${apiType}`;

      return this.axios.post(api, params)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.staffList = res.data.data;
          this.total = res.data.total;
          // Check if staffList items are in selectedVals
          this.staffList.forEach(item => {
            item.selected = this.selectedVals.includes(item.id);
          });
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    assign() {
      if (this.selectedVals.length === 0) {
        this.$toastError('Please select at least one staff member to assign.');
        return;
      }

      const api = `${process.env.VUE_APP_PATH}/notifactions/set_notify_users`;
      this.axios.post(api, 
      { id: this.$route.params.notificationsId, account_ids: this.selectedVals })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update')
          this.$emit('hide')
          this.$toastSuccess('Assign Succeed!');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList().then(() => {
        this.allChecked = this.staffList.every(item => this.selectedVals.includes(item.id));
      });
    },
    accountTypeChange(val) {
      this.listRequest.account_type = val;
    },
    selectChange(val, field) {
      if(field === 'country') {
        this.listRequest.state = '';
        this.listRequest.city = '';
        this.options.state = [];
        this.options.city = [];
        if(val && val !== 'All') {
          this.getState();
        }
      } else if(field === 'state') {
        this.listRequest.city = ''; 
        this.options.city = [];
        if(val && val !== 'All') {
          this.getCity();
        }
      }
    },
    toggleAll(event) {
      const isChecked = event.target.checked;
      this.staffList.forEach(item => {
        item.selected = isChecked;
        if (isChecked) {
          if (!this.selectedVals.includes(item.id)) {
            this.selectedVals.push(item.id);
          }
        } else {
          this.selectedVals = this.selectedVals.filter(id => id !== item.id);
        }
      });
    },
    clearAll() {
      const isAllCleared = !this.listRequest.country && !this.listRequest.state && !this.listRequest.city;
      if (!isAllCleared) {
        this.listRequest = { ...this.listRequest, country: '', state: '', city: '' };
        this.options.state = [];
        this.options.city = [];
        this.listRequest.page = 1;
        this.getList();
      }
    },
    handleSearch() {
      this.allChecked = false;
      if (JSON.stringify(this.listRequest) !== JSON.stringify(this.previousListRequest)) {
        this.previousListRequest = { ...this.listRequest };
        this.listRequest.page = 1;
        this.getList();
      }
    }
  },
  emits: ['hide', 'update']
}
</script>

<style lang="scss">
.current-staff-wrap {
  & table.table {
    width: auto;
    & td {
      border-bottom: none;
    }
  }
}
.select-wrap flex-shrink-0.account {
  width: 140px;
}
</style>