<template>
  <Breadcrumbs :title="`${userData.first_name} ${userData.last_name}`" main="User Management"/>
  <div class="container-fluid">
    <div class="row g-3">
      <div class="col-12 col-md-5">
        <div class="card">
          <div class="card-header">
            <div class="d-flex gap-2 justify-content-between align-items-start mb-4">
              <h5 class="f-w-400 text-secondary">{{ $route.params.userId }}</h5>
              <div class="d-flex gap-2 align-items-center">
                <label :class="[ 'form-check-label', 'mb-0','fw-semibold','fs-6', userData.status ? 'txt-primary' : 'text-secondary']">
                  {{ userData.status ? 'Enabled' : 'Disabled' }}
                </label>                
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch"  :true-value="1" :false-value="0"
                  v-model="userData.status" @click.prevent="confirmSwitch(userData)">
                </div>
              </div>
            </div>
            <div class="d-flex gap-2 justify-content-between align-items-start">
              <div class="d-flex align-items-center gap-2">
                <img class="img-50 rounded-circle" :src="userData.profile ? userData.profile : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                <div>
                  <h6>{{ userData.first_name }} {{ userData.last_name }}</h6>
                  <p class="mb-0 text-secondary">{{ userData.code }} {{ userData.phone }}</p>
                </div>
              </div>
              <div>
                <h6>Created at</h6>
                <p class="mb-0 text-secondary">{{ userData.created_at }}</p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <div class="d-flex justify-content-between mb-3">
                <div>
                  <h6>Local Phone</h6>
                  <p class="text-secondary">{{ userData.code }} {{ userData.phone }}</p>
                </div>
                <div class="fw-semibold">
                  <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                  <a href="javascript:;" class="fs-6 text-primary" @click.prevent="showModal('editUserProfile')">Edit Profile</a>
                </div>
              </div>
              <h6>Mail Address</h6>
              <p class="text-secondary">{{ userData.email }}</p>
              <h6>Postcode</h6>
              <p class="text-secondary">{{ userData.postcode }}</p>
              <h6>Country</h6>
              <p class="text-secondary">{{ userData.country }}</p>
              <h6>State</h6>
              <p class="text-secondary">{{ userData.state }}</p>
              <h6>City</h6>
              <p class="text-secondary">{{ userData.city }}</p>
              <h6>Street Address</h6>
              <p class="text-secondary text-wrap">{{ userData.address }}</p>
              <a href="javascript:;" class="fs-6 fw-semibold" @click.prevent="showModal('resetPassword')">Reset Password</a>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header border-bottom-0">
            <h5 class="f-w-400 text-secondary mb-2">House</h5>
          </div>
          <div v-if="houses.length > 0" :class="['card-body text-nowrap', index !== 0 ? 'border-top' : '']" 
          v-for="(house, index) in houses" :key="house.house_id">
            <div class="d-flex gap-2 mb-3">
              <img :src="house.image ? house.image : 'https://fakeimg.pl/50x50/?text=50x50'" class="img-50" alt="house">
              <div>
                <h6>{{ house.house_name }}</h6>
                <p class="mb-0 text-secondary text-wrap">{{ house.house_address }}</p>
              </div>
            </div>
            <div class="mb-2">
              <h6 class="mb-0">House ID</h6>
              <p class="mb-0 text-secondary">#{{ house.hid }}</p>
            </div>
            <div>
              <div class="row g-2 align-items-center" v-for="(gateway, index) in house.gateways" :key="gateway.gateway_id" 
              :class="{'mb-3': index !== house.gateways.length - 1}">
                <div class="col-12">
                  <div class="d-flex gap-2 align-items-center txt-primary">
                    <vue-feather type="share-2" class="flex-shrink-0"></vue-feather>
                    <div>
                      <p class="mb-0">{{ gateway.gateway_name }}</p>
                      <small class="d-block text-secondary text-wrap">{{ gateway.gid}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-9">
                    <p class="fs-sm mb-0 text-secondary">{{ gateway.nodes_count }} Devices</p>
                    <div v-if="gateway.nodes_count > 0" class="d-flex flex-row align-items-center gap-1 flex-wrap">
                      <Battery60Icon size="18" :class="statusClass(gateway.battery)"/>
                      <WifiStrength3Icon size="18" :class="statusClass(gateway.connection)"/>
                      <ThermometerIcon size="18" :class="statusClass(gateway.temperature)"/>
                      <WaterPercentIcon size="18" :class="statusClass(gateway.humidity)"/>
                      <HomeOutlineIcon size="18" :class="statusClass(gateway.moisture)"/>
                      <p class="ms-2 mb-0" :class="statusClass(gateway.status)">
                        {{ statusText(gateway.status) }}
                      </p>
                    </div>
                </div>
                <div class="col-3">
                  <h5 class="mb-0 text-end">
                    <router-link :to="{ name: 'userDeviceList', params: { houseId: house.house_id } }" class="badge bg-light align-self-start" :class="statusClass(gateway.status)" @click="storeHouseDetails(house)">Detail</router-link>
                  </h5>
                </div>
              </div>
            </div>
            
          </div>
          <div v-else class="text-center">
            <p>No data</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <h5 class="my-4">Report History</h5>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Case ID</th>
                  <th scope="col">House ID</th>
                  <th scope="col" @click="sortList('status')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Status</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('status') && historyRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('status') && historyRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('report_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Report at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('report_at') && historyRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('report_at') && historyRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Assign</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody v-if="reportHistory.length > 0">
                <tr v-for="item in reportHistory" :key="item.id">
                  <td>#{{ item.rid }}</td>
                  <td>
                    <h6 class="text-dark">{{ item.house_name }}</h6>
                    <p class="mb-0">{{ item.house_address }}</p>
                  </td>
                  <td>
                    <p class="mb-0 fs-sm" :class="{
                        'text-warning': item.status === 'pending',
                        'txt-primary': item.status === 'done',
                      }">
                      {{ $capitalizeFirst(item.status) }}
                    </p>
                    <p class="mb-0 fs-sm">{{ item.updated_at }}</p>
                  </td>
                  <td>
                    <p class="mb-0 fs-sm" :class="statusClass(item.report_device.status)">
                      {{ item.report_device.count }} Devices 
                      {{ statusText(item.report_device.status) }}
                    </p>
                    <p class="mb-0 fs-sm">{{ item.report_at }}</p>
                  </td>
                  <td>
                    <div class="d-flex align-items-center gap-2" v-if="item.staff">
                      <img class="img-50 rounded-circle" :src="item.staff.profile ? item.staff.profile : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                      <p class="mb-0 text-dark">{{ item.staff.first_name }} {{ item.staff.last_name }}</p>
                    </div>
                  </td>
                  <td>
                    <button type="button" class="btn btn-outline-primary" @click.prevent="showModal('assignHomeService', item)">Assign</button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">
                    No data available in table
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <resetPassword v-if="shown.resetPassword" ref="resetPassword" 
  :userId="userData.user_id" @hide="hideModal('resetPassword')"/>
  <editUserProfile v-if="shown.editUserProfile" ref="editUserProfile" 
  :userData="userData" @hide="hideModal('editUserProfile')" @getUserData="getUserData"/>
  <assignHomeService v-if="shown.assignHomeService" ref="assignHomeService" 
  :staffData="selectedStaff" :reportId="reportId" @hide="hideModal('assignHomeService')" @update="getReportHistory"/>
  <switchCheck v-if="shown.switchCheck" ref="switchCheck" :text="text" @handleSwitch="switchUserStatus" @hide="hideModal('switchCheck')" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import resetPassword from '@/components-gc/modal/member/resetPassword.vue'
import editUserProfile from '@/components-gc/modal/userManagement/editUserProfile.vue'
import assignHomeService from '@/components-gc/modal/userManagement/assignHomeService.vue'
import switchCheck from '@/components-gc/modal/switchCheck.vue'
import { checkStatusCode } from '@/methods-gc/statusCode';
import { transformMixin } from '@/methods-gc/transform';
import { mapMutations } from 'vuex';

export default {
  mixins: [transformMixin],
  data() {
    return {
      userData: {},
      houses: [],
      reportHistory: [],
      historyRequest: {
        user_id: this.$route.params.userId,
        order_by: '',
        order: ''
      },
      modals: {},
      shown: {
        resetPassword: false,
        editUserProfile: false,
        switchCheck: false,
      },
      selectedStaff: null,
      reportId: null,
      switchItem: null,
    }
  },
  components: { 
    resetPassword,
    editUserProfile,
    assignHomeService,
    switchCheck
  },
  mounted() {
    this.getUserData();
    this.getReportHistory();
  },
  methods: {
    ...mapMutations('user', ['setHouseDevice']),
    showModal(name, data = null) {
      if (name === 'assignHomeService') {
        this.selectedStaff = data.staff;
        this.reportId = data.id;
      }
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
    getUserData() {
      const api = `${process.env.VUE_APP_PATH}/user/detail`;
      this.axios.post(api, {
        user_id: this.$route.params.userId
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.userData = res.data.user;
          this.houses = res.data.houses;
          this.getReportHistory();
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getReportHistory() {
      const api = `${process.env.VUE_APP_PATH}/user/report_history`;
      this.axios.post(api, this.historyRequest)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.reportHistory = res.data.data;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    sortList(column) {
      if (this.historyRequest.order_by === column) {
        this.historyRequest.order = this.historyRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.historyRequest.order_by = column;
        this.historyRequest.order = 'asc';
      }
      this.getReportHistory();
    },
    isActiveSort(column) {
      return this.historyRequest.order_by === column;
    },
    confirmSwitch(item) {
      this.switchItem = item;
      this.showModal('switchCheck');
    },
    switchUserStatus() {
      this.hideModal('switchCheck');
      const api = `${process.env.VUE_APP_PATH}/user/switch`;
      this.axios.post(api, {
        user_id: this.switchItem.user_id,
        enable: !this.switchItem.status
      })
      .then((res) => {
        if (res.data.status_code !== 'SYSTEM_1000') {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        } else {
          this.getUserData();
          this.$toastSuccess('Status updated successfully');
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    storeHouseDetails(house) {
      this.setHouseDevice({
        firstName: this.userData.first_name,
        lastName: this.userData.last_name,
        houseName: house.house_name,
        houseAddress: house.house_address,
        hid: house.hid
      });
    }
  }
}
</script>

<style>
.table-responsive {
  max-height: 1000px;
}
</style>