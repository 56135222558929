<template>
  <Breadcrumbs :title="`${houseDevice.firstName} ${houseDevice.lastName}`" main="Staff Management" :title2="houseDevice.houseName"/>
  <div class="container-fluid">
    <div class="d-flex justify-content-between gap-2">
      <div>
        <div class="d-flex align-items-center gap-1 mb-2">
          <router-link :to="{ name: 'staffCaseDetail' }" class="link-dark">
            <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
          </router-link>
          <h5 class="mb-0">{{ houseDevice.houseName }}</h5>
        </div>
        <p class="mb-0 text-secondary">{{ houseDevice.houseAddress }}</p>
      </div>
      <button type="button" class="btn btn-third align-self-center text-nowrap" @click.prevent="fetchQRcode">QR Code</button>
    </div>
    <div class="row g-3">
      <div class="col-md-8">
        <GoogleMap v-if="showMap"
          :api-key="apiKey"
          style="width: 100%; height: 1000px"
          :center="center"
          :zoom="15"
          >
            <Marker :options="{ position: center }" />
        </GoogleMap>
        <div v-else>
          <p class="text-center">No map found for this address</p>
        </div>
      </div>
      <div class="col-md-4">
          <router-view></router-view>
      </div>
    </div>
  </div>
    <downloadQR v-if="shown.downloadQR" ref="downloadQR" :qrcodeImg="qrcodeImg" @hide="hideModal('downloadQR')" />
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { GoogleMap, Marker } from 'vue3-google-map'
import { mapState } from 'vuex';
import Modal from "bootstrap/js/dist/modal";
import downloadQR from '@/components-gc/modal/downloadQR.vue'
import { getQRcode, createQRImg } from '@/methods-gc/qrCode';


export default {
  data() {
    return {
      showMap: false,
      center: { lat: 0, lng: 0 },
      qrcodeImg: '',
      verifyCode: '',
      modals: {},
      shown: {
        downloadQR: false,
      },
    }
  },
  mounted() {
    this.initMap();
  },
  components: {
    GoogleMap, Marker, downloadQR
  },
  computed: {
        ...mapState('user', ['houseDevice']),
    },
  methods: {
    initMap() {
      const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
          version: "weekly",
          language: "en" 
        });
      
      loader.load().then(async () => {
        const { Geocoder } = await google.maps.importLibrary("geocoding")
        
        /* 將地址轉為經緯度 */
        const geocoder = new Geocoder();
        geocoder.geocode({ 'address': this.houseDevice.houseAddress }, (results, status) => {
          if (status === 'OK') {
            this.center.lat = results[0].geometry.location.lat(); 
            this.center.lng = results[0].geometry.location.lng(); 
            this.showMap = true;
          } else {
            console.error('查無座標：', status);
          }
        });
      });
    },
    async fetchQRcode() {
      try {
        const { qrcodeImg, verifyCode } = await getQRcode(this.$route.params.houseId);
        this.qrcodeImg = qrcodeImg;
        this.verifyCode = verifyCode;
        this.qrcodeImg = await createQRImg(qrcodeImg, this.houseDevice.houseName, this.houseDevice.hid, verifyCode);
        this.showModal('downloadQR');
      } catch (error) {
        this.$toastError(error.message);
      }
    },
    showModal(name, data = null) {
      if (name === 'assignHomeService') {
        this.selectedStaff = data.staff;
        this.reportId = data.id;
      }
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
  },
}
</script>