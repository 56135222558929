<template>
  <Breadcrumbs title="User Management"/>
  <div class="container-fluid">
    <div class="row g-3">
      <div class="col-12">
        <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center 
        justify-content-between mb-3 gap-3">
          <div class="d-flex gap-3 flex-column flex-md-row flex-shrink-0">
            <div class="flex-shrink-0">
              <CustomSelect
                v-model="listRequest.country"
                :options="options.country"
                placeholder="Country"
                field="country"
                @change="selectChange"
              />
            </div>
            <div class="flex-shrink-0">
              <CustomSelect
                v-model="listRequest.state"
                :options="options.state"
                placeholder="State"
                field="state"
                @change="selectChange"
              />
            </div>
            <div class="flex-shrink-0">
              <CustomSelect
                v-model="listRequest.service"
                :options="options.service"
                placeholder="Service"
                field="service"
                @change="selectChange"
              />
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
            <a href="javascript:void(0)" class="align-self-center clear-btn" @click.prevent="clearAll">Clear all</a>
          </div>
          <div class="position-relative align-self-md-center search-box flex-shrink-1">
            <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
            <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>User</th>
                  <th scope="col">Country</th>
                  <th scope="col">State</th>
                  <th scope="col" @click="sortList('house')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">House</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('house') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('house') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('status')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Status</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Active</th>
                  <th scope="col" @click="sortList('home_service')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Home Service</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('home_service') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('home_service') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('created_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Created at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody v-if="userList.length > 0" >
                <tr v-for="item in userList" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td class="d-flex align-items-center gap-2">
                    <img class="img-40 rounded-circle" :src="item.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                    <div>
                      <p class="mb-0 text-dark">{{ item.first_name }} {{ item.last_name }}</p>
                      <p class="mb-0">{{ item.code }} {{ item.phone }}</p>
                    </div>
                  </td>
                  <td>{{ item.country }}</td>
                  <td>{{ item.state }}</td>
                  <td>{{ item.House }}</td>
                  <td>
                    <div class="d-flex gap-2 align-items-center">
                      <!-- <label :class="['form-check-label', 'mb-0', { 'txt-primary': item.status }]">{{ item.status ? 'Enabled' : 'Disabled' }}</label> -->
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" :checked="item.status" @click.prevent="confirmSwitch('status', item)">
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex gap-2 align-items-center">
                      <!-- <label :class="['form-check-label',  'mb-0', { 'txt-primary': item.active }]">{{ item.active ? 'Enabled' : 'Disabled' }}</label> -->
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" :checked="item.active" @click.prevent="confirmSwitch('active', item)">
                      </div>
                    </div>
                  </td>
                  <td :class="item.home_service.warning ? 'txt-secondary' : 'txt-primary'">{{ item.home_service.count }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <!-- <router-link :to="{ name: 'userManagementViewDetail', params: { userId: item.id, status: item.status } }">View Details</router-link> -->
                    <router-link :to="{ name: 'userManagementViewDetail', params: { userId: item.id, status: item.status } }">
                      <i class="fa fa-file-text-o fs-5" aria-hidden="true"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">
                    {{ listRequest.search ? 'No matching records found' : 'No data available in table' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
  <SwitchCheck v-if="shown.switchCheck" ref="switchCheck" :text="text" @handleSwitch="handleSwitch" @hide="hideModal('switchCheck')" />
</template>

<script>
import Pagination from '@/components-gc/Pagination.vue';
import Modal from "bootstrap/js/dist/modal";
import SwitchCheck from '@/components-gc/modal/switchCheck.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';
import CustomSelect from '@/components-gc/CustomSelect.vue'

export default {
  mounted() {
    this.getCountry();
    this.getList();
  },
  data() {
    return {
      userList: [],
      options: {
        country: [],
        state: [],
        service: ['All','Pending', 'Done']
      },
      listRequest: {
        country: "",
        state: "",
        service: "",
        search: "",
        page: 1,
        limit: 10,
        order_by: "",
        order: "",
      },
      total: 0,
      modals: {},
      shown: {
        switchCheck: false
      },
      switchType: '',
      switchItem: null,
      previousListRequest: {}
    }
  },
  components: {
    Pagination,
    SwitchCheck,
    CustomSelect
  },
  watch: {
    'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.listRequest.page = 1;
        this.getList();
      }
    }
  },
  methods: {
    getCountry() {
      const api = `${process.env.VUE_APP_PATH}/global/countries`;
      this.axios.get(api, {
        params: { type: 'user' }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.country = res.data.countries;
          this.options.country.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getState() {
      const api = `${process.env.VUE_APP_PATH}/global/states`;
      this.axios.get(api, {
        params: { type: 'user', country: this.listRequest.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.state = res.data.states;
          this.options.state.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    selectChange(val, field) {
      if(field === 'country') {
        this.listRequest.state = '';
        this.options.state = [];
        if(val && val !== 'All') {
          this.getState();
        }
      }
    },
    getList() {
      const params = { ...this.listRequest };
      Object.keys(params).forEach(key => {
        if (params[key] === 'All') {
          params[key] = '';
        }
        if(params[key] !== null && typeof params[key] === 'string') {
          params[key] = params[key].toLowerCase();
        }
      });

      const api = `${process.env.VUE_APP_PATH}/user/list`;
      this.axios.post(api, params)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.userList = res.data.data;
          this.total = res.data.total;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
    confirmSwitch(type, item) {
      this.switchType = type;
      this.switchItem = item;
      this.showModal('switchCheck');
    },
    handleSwitch() {
      this.hideModal('switchCheck');
      if (this.switchType === 'status' || this.switchType === 'active') {
        this.switchUserType(this.switchItem, this.switchType);
      }
    },
    switchUserType(item, type) {
      const api = `${process.env.VUE_APP_PATH}/user/${type === 'status' ? 'switch' : 'active'}`;
      const paramName = type === 'status' ? 'enable' : 'active';
      const payload = {
        user_id: item.id,
        [paramName]: !item[type]
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code !== 'SYSTEM_1000') {
          this.$toastError(checkStatusCode(res.data.status_code));
        } else {
          this.$toastSuccess(`${type.charAt(0).toUpperCase() + type.slice(1)} updated successfully`);
          this.getList();
        }
      }).catch(error => {
        console.error('Error:', error);
      });
    },
    clearAll() {
      const isAllCleared = !this.listRequest.country && !this.listRequest.state && !this.listRequest.service;
      if (!isAllCleared) {
        this.listRequest = { ...this.listRequest, country: '', state: '', service: '' };
        this.options.state = [];
        this.listRequest.page = 1;
        this.getList();
      }
    },
    handleSearch() {
      if (JSON.stringify(this.listRequest) !== JSON.stringify(this.previousListRequest)) {
        this.previousListRequest = { ...this.listRequest };
        this.listRequest.page = 1;
        this.getList();
      }
    },
  }
}
</script>

