<template>
  <div class="modal fade" id="editContentModal" tabindex="-1" aria-labelledby="editContentLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form  @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit Contents</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-md-12">
                <div>
                  <quill-editor v-model:value="localDetail.content" :options="editorOption" 
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      editorOption: {
        placeholder: 'content',
      },
      localDetail: { ...this.detail }
    }
  },
  props: ['detail'],
  emits: ['hide', 'update'],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/about_us/update_content`;
      const payload = {
        id: this.$route.params.aboutId,
        content: this.localDetail.content
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update');
          this.$emit('hide');
          this.$toastSuccess('Updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  }
}
</script>