<template>
  <Breadcrumbs title="Device Management" />
  <div class="container-fluid">
    <div class="row g-3">
      <div class="col-12">
        <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center 
        justify-content-between mb-3 gap-3">
          <div class="d-flex gap-3 flex-column flex-md-row flex-shrink-0">
            <div class="select-wrap flex-shrink-0">
              <CustomSelect
                v-model="selected.status"
                :options="options.status"
                field="status"
                placeholder="Status"
                @change="selectChange"
              />
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
            <a href="javascript:void(0)" class="align-self-center clear-btn" @click.prevent="clearAll">Clear all</a>
          </div>
          <div class="d-flex gap-3">
            <div class="position-relative align-self-md-center search-box flex-grow-1 flex-md-grow-0">
              <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
              <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
            </div>
            <button type="button" class="btn btn-primary text-nowrap" @click.prevent="showModal('import')">+ Import From File</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'gateway' ? 'txt-primary active' : '']" @click.prevent="changeTab('gateway')">
            Gateway
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'sensor' ? 'txt-primary active' : '']" @click.prevent="changeTab('sensor')">
            Sensor
          </a>
        </li>
      </ul>

      <div class="table-responsive">
        <table class="table align-middle">
          <thead>
            <tr>
              <th scope="col">Index</th>
              <th scope="col">Name</th>
              <th scope="col">Series Number</th>
              <th scope="col">House Id</th>
              <th scope="col">Group</th>
              <th scope="col">Status</th>
              <th scope="col">Active</th>
              <th scope="col" @click="sortList('install_at')" class="cursor-p">
                <div class="d-flex justify-content-between align-items-center gap-2">
                  <p class="mb-0">Install at</p>
                  <div>
                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('install_at') && listRequest.order === 'asc' }"></i>
                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('install_at') && listRequest.order === 'desc' }"></i>
                  </div>
                </div>
              </th>
              <!-- <th scope="col">QR Code</th> -->
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody v-if="data.length > 0">
            <tr v-for="item in data" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
               <td>{{ item.series_number }}</td>
              <td>{{ item.hid }}</td>
              <td>{{ item.group_id }}</td>
              <td>
                <div class="light-signal" :class="item.detection"></div>
              </td>
              <td>
                <div class="d-flex gap-2 align-items-center">
                   <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="item.status" :true-value="1" :false-value="0" 
                        @click.prevent="confirmSwitch(item)">
                    </div>
                </div>
              </td>
              <td>{{ item.install_at }}</td>
              <td>
                <a v-if="item.printable" href="javascript:;" class="me-3" @click.prevent="fetchQRcode(item.id, item.name, item.series_number)">
                  <i class="fa fa-qrcode fs-5" aria-hidden="true"></i>
                </a>
                <span>
                  <router-link 
                    v-if="listRequest.type === 'gateway' && item.house_id" 
                    :to="{ 
                      name: 'houseGateway', 
                      params: { houseId: item.house_id, gatewayId: item.id },
                      query: { 
                        search: listRequest.search,
                        status: selected.status,
                        page: listRequest.page,
                        type: listRequest.type
                      }
                    }" 
                    class="link-primary">
                    <i class="fa fa-file-text-o fs-5" aria-hidden="true"></i>
                  </router-link>
                  <router-link 
                    v-else-if="listRequest.type === 'sensor' && item.house_id" 
                    :to="{ 
                      name: 'houseSensor', 
                      params: { houseId: item.house_id, sensorId: item.id },
                      query: { 
                        search: listRequest.search,
                        status: selected.status,
                        page: listRequest.page,
                        type: listRequest.type
                      }
                    }" 
                    class="link-primary">
                    <i class="fa fa-file-text-o fs-5" aria-hidden="true"></i>
                  </router-link>
                </span>
            </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9" class="text-center">
                {{ listRequest.search ? 'No matching records found' : 'No data available in table' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
  </div>
  
  <Import ref="import" v-if="shown.import" @hide="hideModal('import')" @update="getList" :type="$capitalizeFirst(listRequest.type)" />
  <downloadQR v-if="shown.downloadQR" ref="downloadQR" :qrcodeImg="qrcodeImg" @hide="hideModal('downloadQR')" />
  <switchCheck v-if="shown.switchCheck" ref="switchCheck" :text="text" @handleSwitch="switchStaffStatus" @hide="hideModal('switchCheck')" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import Import from '@/components-gc/modal/deviceManagement/import.vue';
import downloadQR from '@/components-gc/modal/downloadQR.vue'
import switchCheck from '@/components-gc/modal/switchCheck.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';
import { getQRcode, createQRImg } from '@/methods-gc/qrCode';
import _ from 'lodash'; 
import CustomSelect from '@/components-gc/CustomSelect.vue';

export default {
  data() {
    return {
      options: {
        status: ['All', 'Enabled', 'Disabled'],
      },
      selected: {
        status: '',
      },
      modals: {},
      shown: {
        import: false,
        downloadQR: false,
        switchCheck: false
      },
      data: [],
      listRequest: {
        type: "gateway",
        status: "",
        search: "",
        order_by: "",
        order: "",
        page: 1,
        limit: 10,
      },
      total: 0,
      qrcodeImg: '',
      switchItem: null,
      previousListRequest: {},
    };
  },
  components: {
    Import,
    Pagination,
    downloadQR,
    switchCheck,
    CustomSelect
  },
  mounted() {
    if (this.$route.query) {
       this.listRequest.search = this.$route.query.search || '';
       this.listRequest.page = parseInt(this.$route.query.page) || 1;
       this.selected.status = this.$route.query.status || '';
       this.selectChange(this.selected.status);
       this.listRequest.type = this.$route.query.type || 'gateway';
     }
    this.getList();
  },
  methods: {
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    changeTab(tab) {
      this.listRequest.type = tab;
      this.listRequest.page = 1;
      this.getList();
    },
    getList(type = null) {
      if(type) {
        this.listRequest.type = type.toLowerCase();
      }
      const api = `${process.env.VUE_APP_PATH}/device/list`;
      this.axios.post(api, this.listRequest)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.data = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    selectChange(val) {
      if (val && val !== 'All') {
        this.listRequest.status = val === 'Enabled' ? 'Y' : 'N';
      } else {
        this.listRequest.status = '';
      }
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    confirmSwitch(item) {
      this.switchItem = item;
      this.showModal('switchCheck');
    },
    switchStaffStatus: _.debounce(function () {
      this.hideModal('switchCheck');
      const api = `${process.env.VUE_APP_PATH}/device/switch`;
      this.axios.post(api, {
        type: this.listRequest.type,
        id: this.switchItem.id,
        enable: !this.switchItem.status
      })
        .then((res) => {
          if (res.data.status_code !== 'SYSTEM_1000') {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          } else {
            this.getList();
            this.$toastSuccess('Status updated successfully');
          }
        }).catch(error => {
          console.log('Error:', error);
        });
      }, 300, { leading: true, trailing: false }
    ),
    async fetchQRcode(id, name, series_number) {
      const apiType = this.listRequest.type;
      try {
        const { qrcodeImg } = await getQRcode(id, apiType);
        this.qrcodeImg = qrcodeImg;
        this.qrcodeImg = await createQRImg(qrcodeImg, name, series_number);
        this.showModal('downloadQR');
      } catch (error) {
        console.log(error.message);
      }
    },
    clearAll() {
      const isAllCleared = !this.listRequest.status;
      if (!isAllCleared) {
        this.selected.status = '';
        this.listRequest = { ...this.listRequest, status: '' };
        this.listRequest.page = 1;
        this.getList();
      }
    },
    handleSearch() {
      if (JSON.stringify(this.listRequest) !== JSON.stringify(this.previousListRequest)) {
        this.previousListRequest = { ...this.listRequest };
        this.listRequest.page = 1;
        this.getList();
      }
    }
  },
   watch: {
     'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.listRequest.page = 1;
        this.getList();
      }
    }
  },
}
</script>

<style>
.light-signal {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    &.good {
        background-color: var(--theme-deafult);
    }
    &.risk {
        background-color: var(--bs-yellow);
    }
    &.critical {
        background-color: var(--bs-red);
    }
}
</style>
