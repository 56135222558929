<template>
  <Breadcrumbs title="Notifications" />
  <div class="container-fluid">
    <div class="row g-3">
      <div class="col-12">
         <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center 
        justify-content-between mb-3 gap-3">
          <div class="d-flex gap-3 flex-column flex-md-row flex-shrink-0">
            <div class="select-wrap flex-shrink-0">
              <CustomSelect
                v-model="listRequest.status"
                :options="options.status"
                field="status"
                placeholder="Status"
              />
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
            <a href="javascript:void(0)" class="align-self-center clear-btn" @click.prevent="clearAll">Clear all</a>
          </div>
          <div class="d-flex gap-3">
            <div class="position-relative align-self-md-center search-box flex-grow-1 flex-md-grow-0">
              <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
              <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
            </div>
            <button type="button" class="btn btn-primary text-nowrap" @click.prevent="showModal('addNew')">+ Add New</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'official' ? 'txt-primary active' : '']" @click.prevent="changeTab('official')">
            Home Page
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'events' ? 'txt-primary active' : '']" @click.prevent="changeTab('events')">
            Events
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'specify' ? 'txt-primary active' : '']" @click.prevent="changeTab('specify')">
            Specify
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'staff' ? 'txt-primary active' : '']" @click.prevent="changeTab('staff')">
            Staff
          </a>
        </li>
      </ul>

      <div class="table-responsive">
        <table class="table align-middle">
          <thead>
            <tr>
              <th scope="col">Index</th>
              <th scope="col">Title</th>
              <th scope="col">Editor</th>
              <th scope="col">Status</th>
              <th scope="col">Link</th>
              <th scope="col" @click="sortList('created_at')" class="cursor-p">
                <div class="d-flex justify-content-between align-items-center gap-2">
                  <p class="mb-0">Create at</p>
                  <div>
                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                  </div>
                </div>
              </th>
              <th scope="col" @click="sortList('publish_at')" class="cursor-p">
                <div class="d-flex justify-content-between align-items-center gap-2">
                  <p class="mb-0">Publish at</p>
                  <div>
                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('publish_at') && listRequest.order === 'asc' }"></i>
                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('publish_at') && listRequest.order === 'desc' }"></i>
                  </div>
                </div>
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody  v-if="data.length > 0">
            <tr v-for="item in data" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="fw-semibold">
                <div class="single-ellipsis">{{ item.title }}</div>
              </td>
               <td >
                  <div v-if="item.editor" class="d-flex align-items-center gap-2">
                    <img class="img-40 rounded-circle" :src="item.editor.profile ? item.editor.profile : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                    <div>
                      <p class="mb-0 text-dark">{{ item.editor.first_name }} {{ item.editor.last_name }}</p>
                      <p class="mb-0">{{ item.editor.email }}</p>
                    </div>
                  </div>
                </td>
              <td>
                <p class="mb-0 fs-sm fw-semibold" :class="{
                    'txt-primary': item.status === 'published',
                    'text-secondary': item.status === 'waiting'
                  }">
                   {{ $capitalizeFirst(item.status) }}
                </p>
              </td>
              <td>
                <a :href="item.url">Link</a>
              </td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.publish_at }}</td>
              <td>
                <div>
                  <router-link 
                    :to="{ 
                      name: 'notificationsDetail', 
                      params: { notificationsId: item.id },
                      query: { 
                        search: listRequest.search,
                        status: listRequest.status,
                        page: listRequest.page,
                        type: listRequest.type
                      }
                    }" 
                    class="me-3">
                    <i class="fa fa-file-text-o fs-5" aria-hidden="true"></i>
                  </router-link>
                  <a href="javascript:;" @click.prevent="confirmDelete(item.id)">
                    <i class="fa fa-trash-o fs-4" aria-hidden="true"></i>
                  </a>
                  <a v-if="item.status === 'waiting'" href="javascript:;" class="ms-3" @click.prevent="confirmPublish(item.id)">
                    <i class="fa fa-paper-plane-o fs-5" aria-hidden="true"></i>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="8" class="text-center">
                {{ listRequest.search ? 'No matching records found' : 'No data available in table' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
  </div>
  <deleteModal v-if="shown.deleteModal" ref="deleteModal" :type="'notification'"  @update="handleDelete" @hide="hideModal('deleteModal')" />
  <publishModal v-if="shown.publishModal" ref="publishModal"  @update="handlePublish" @hide="hideModal('publishModal')" />
  <addNew ref="addNew" v-if="shown.addNew" @hide="hideModal('addNew')" @update="getList" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import addNew from '@/components-gc/modal/notifications/addNew.vue';
import deleteModal from '@/components-gc/modal/delete.vue';
import publishModal from '@/components-gc/modal/publish.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';
import CustomSelect from '@/components-gc/CustomSelect.vue';

export default {
  data() {
    return {
      options: {
        status: ['All', 'Waiting', 'Published'],
      },
      modals: {},
      shown: {
        addNew: false,
        deleteModal: false,
        publishModal: false,
      },
      data: [],
      listRequest: {
        type: "official",
        status: "",
        search: "",
        order_by: "",
        order: "",
        page: 1,
        limit: 10,
      },
      total: 0,
      previousListRequest: {},
      notificationId: null,
    };
  },
  components: {
    addNew,
    Pagination,
    deleteModal,
    publishModal,
    CustomSelect,
  },
  mounted() {
    if (this.$route.query) {
       this.listRequest.search = this.$route.query.search || '';
       this.listRequest.page = parseInt(this.$route.query.page) || 1;
       this.listRequest.status = this.$route.query.status || '';
       this.listRequest.type = this.$route.query.type || 'official';
     }
    this.getList();
  },
  watch: {
    // '$route'(newVal, oldVal) {
    //   if (newVal.fullPath !== oldVal.fullPath) {
    //     this.listRequest.page = 1;
    //     this.getList();
    //   }
    // },
    'listRequest.search'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.listRequest.page = 1;
        this.getList();
      }
    }
  },
  methods: {
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    changeTab(tab) {
      this.listRequest.type = tab;
      this.getList();
    },
    getList() {
      const params = { ...this.listRequest };
      Object.keys(params).forEach(key => {
        if (params[key] === 'All') {
          params[key] = '';
        }
        if(params[key] !== null && typeof params[key] === 'string') {
          params[key] = params[key].toLowerCase();
        }
      });

      const api = `${process.env.VUE_APP_PATH}/notifactions/list`;
      this.axios.post(api, params)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.data = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    clearAll() {
      const isAllCleared = !this.listRequest.status;
      if (!isAllCleared) {
        this.listRequest = { ...this.listRequest, status: '' };
        this.listRequest.page = 1;
        this.getList();
      }
    },
    handleSearch() {
      if (JSON.stringify(this.listRequest) !== JSON.stringify(this.previousListRequest)) {
        this.previousListRequest = { ...this.listRequest };
        this.listRequest.page = 1;
        this.getList();
      }
    },
    confirmDelete(id) {
      this.notificationId = id;
      this.showModal('deleteModal');
    },
    confirmPublish(id) {
      this.notificationId = id;
      this.showModal('publishModal');
    },
    handleDelete() {
      this.hideModal('deleteModal');

      const api = `${process.env.VUE_APP_PATH}/notifactions/delete`;
      const payload = {
        id: this.notificationId
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('Notification has been deleted successfully');
          this.hideModal('deleteModal');
          this.listRequest.page = 1;
          this.getList();
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    handlePublish() {
      const api = `${process.env.VUE_APP_PATH}/notifactions/directly_publish`;
      const payload = {
        id: this.notificationId
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('Notification has been published successfully');
          this.hideModal('publishModal');
          this.getList();
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
}
</script>

<style scoped>
@media (max-width: 1400px) {
  .single-ellipsis {
    max-width: 300px;
  }
}
</style>