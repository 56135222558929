<template>
  <div class="modal fade" id="editAssignModal" tabindex="-1" aria-labelledby="editAssignLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <Form>
          <div class="modal-header">
            <h1 class="modal-title fs-5">Assign Staff</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt-0">
            <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center 
        justify-content-between mb-3 gap-3">
          <div class="d-flex gap-3 flex-column flex-md-row flex-shrink-0">
                <div class="select-wrap flex-shrink-0">
                  <Field name="country" v-slot="{ field }">
                    <CustomSelect
                      v-model="listRequest.country"
                      :options="options.country"
                      :field="'country'"
                      placeholder="Country"
                      v-bind="field"
                      @change="selectChange"
                    />
                    <ErrorMessage name="country" class="text-danger"></ErrorMessage>
                  </Field>
                </div>
                <div class="select-wrap flex-shrink-0">
                  <Field name="state" v-slot="{ field }">
                    <CustomSelect
                      v-model="listRequest.state"
                      :options="options.state"
                      :field="'state'"
                      placeholder="State"
                      v-bind="field"
                      @change="selectChange"
                    />
                    <ErrorMessage name="state" class="text-danger"></ErrorMessage>
                  </Field>
                </div>
                <div class="select-wrap flex-shrink-0">
                  <Field name="city" v-slot="{ field }">
                    <CustomSelect
                      v-model="listRequest.city"
                      :options="options.city"
                      :field="'city'"
                      placeholder="City"
                      v-bind="field"
                    />
                    <ErrorMessage name="city" class="text-danger"></ErrorMessage>
                  </Field>
                </div>
                <button type="button" class="btn btn-third" @click.prevent="handleSearch">Search</button>
                <a href="javascript:void(0)" class="align-self-center clear-btn" @click.prevent="clearAll">Clear all</a>
              </div>
          <div class="position-relative align-self-md-center search-box flex-shrink-1">
                <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
                <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
              </div>
            </div>
            <div class="table-responsive staff-wrap">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Staff</th>
                    <th scope="col">Country</th>
                    <th scope="col">State</th>
                    <th scope="col">City</th>
                    <th scope="col" @click="sortList('service_case')" class="cursor-p">
                      <div class="d-flex justify-content-between align-items-center gap-2">
                        <p class="mb-0">Service Case</p>
                        <div>
                          <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('service_case') && listRequest.order === 'asc' }"></i>
                          <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('service_case') && listRequest.order === 'desc' }"></i>
                        </div>
                      </div>
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody v-if="staffList.length > 0">
                  <tr v-for="item in staffList" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>
                      <div class="d-flex align-items-center gap-2">
                        <img class="img-40 rounded-circle" :src="item.profile ? item.profile : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                        <div>
                          <p class="mb-0 text-dark">{{ item.first_name }} {{ item.last_name }}</p>
                          <p class="mb-0">{{ item.email }}</p>
                        </div>
                      </div>
                    </td>
                    <td>{{ item.country }}</td>
                    <td>{{ item.state }}</td>
                    <td>{{ item.city }}</td>
                    <td>
                      <div v-if="item.service_case">
                        <span class="txt-primary">{{ item.service_case.assigned }} Assigned / </span>
                        <span class="text-danger">{{ item.service_case.pending }} Pending</span>
                      </div>
                      <span v-else>Unassigned</span>
                    </td>
                    <td>
                      <div class="ms-3 radio radio-primary">
                        <input :id="item.id" type="radio" name="assign" v-model="selectedStaff" :value="item.id">
                        <label :for="item.id"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">
                      {{ listRequest.search ? 'No matching records found' : 'No data available in table' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="assignStaff">Assign</button>
          </div>
        </Form>
      </div>
    </div>
  </div>

</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';
import CustomSelect from '@/components-gc/CustomSelect.vue';

export default {
  data() {
    return {
      options: {
        country: [],
        state: [],
        city: [],
      },
      staffList: [],
      listRequest: {
        country: "",
        state: "",
        city: "",
        search: "",
        assigned: "",
        page: 1,
        limit: 5,
        order_by: "",
        order: "",
        enabled: "Y"
      },
      total: null,
      selectedStaff: null, 
      previousListRequest: {},
    }
  },
  props: ['staffData'],
  mounted() {
    this.getCountry();
    this.getList();
     if (this.staffData) {
      this.selectedStaff = this.staffData.id;
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Pagination,
    CustomSelect,
  },
  watch: {
    'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.listRequest.page = 1;
        this.getList();
      }
    }
  },
  methods: {
    getCountry() {
      const api = `${process.env.VUE_APP_PATH}/global/countries`;
      this.axios.get(api, {
        params: { type: 'staff' }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.country = res.data.countries;
          this.options.country.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getState() {
      const api = `${process.env.VUE_APP_PATH}/global/states`;
      this.axios.get(api, {
        params: { type: 'staff', country: this.listRequest.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.state = res.data.states;
          this.options.state.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getCity() {
      const api = `${process.env.VUE_APP_PATH}/global/cities`;
      this.axios.get(api, {
        params: { type: 'staff', state: this.listRequest.state, country: this.listRequest.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.city = res.data.cities;
          this.options.city.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    selectChange(val, field) {
      if(field === 'country') {
        this.listRequest.state = '';
        this.listRequest.city = '';
        this.options.state = [];
        this.options.city = [];
        if(val && val !== 'All') {
          this.getState();
        }
      } else if(field === 'state') {
        this.listRequest.city = ''; 
        this.options.city = [];
        if(val && val !== 'All') {
          this.getCity();
        }
      }
    },
    getList() {
      const params = { ...this.listRequest };
      Object.keys(params).forEach(key => {
        if (params[key] === 'All') {
          params[key] = '';
        }
      });
      
      const api = `${process.env.VUE_APP_PATH}/staff/list`;
      this.axios.post(api, params)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.staffList = res.data.data;
          this.total = res.data.total;
          // 如果 selectedStaff 有值，將對應的 radio 設為 checked
          if (this.selectedStaff) {
            this.$nextTick(() => {
              const selectedRadio = document.getElementById(this.selectedStaff);
              if (selectedRadio) {
                selectedRadio.checked = true;
              }
            });
          }
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    assignStaff() {
      if (!this.selectedStaff) {
        this.$toastError('Please select a staff member to assign.');
        return;
      }

      const api = `${process.env.VUE_APP_PATH}/house/set_staff`;
      this.axios.post(api, 
      { house_id: this.$route.params.houseId, user_id: parseInt(this.selectedStaff) })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update')
          this.$emit('hide')
          this.$toastSuccess('Assign Succeed!');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    clearAll() {
      const isAllCleared = !this.listRequest.country && !this.listRequest.state && !this.listRequest.city;
      if (!isAllCleared) {
        this.listRequest = { ...this.listRequest, country: '', state: '', city: '' };
        this.options.state = [];
        this.options.city = [];
        this.listRequest.page = 1;
        this.getList();
      }
    },
    handleSearch() {
      if (JSON.stringify(this.listRequest) !== JSON.stringify(this.previousListRequest)) {
        this.previousListRequest = { ...this.listRequest };
        this.listRequest.page = 1;
        this.getList();
      }
    },
  },
  emits: ['hide', 'update']
}
</script>

<style lang="scss">
.current-staff-wrap {
  & table.table {
    width: auto;
    & td {
      border-bottom: none;
    }
  }
}
</style>