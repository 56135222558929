<template>
  <div class="modal fade" id="importModal" tabindex="-1" aria-labelledby="addNewLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Import From File</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <label for="file" class="col-form-label fw-semibold">Device Type</label>
            <div class="select-wrap mb-3">
              <CustomSelect
                v-model="selectedType"
                :options="optionsType"
                field="deviceType"
                :allow-empty="false"
                placeholder="Select device type"
              />
            </div>
            <div>
              <label for="file" class="col-form-label fw-semibold">Upload File</label>
              <Field name="file" type="file" class="form-control" id="file" rules="required|ext:csv,txt" v-model="file" :validateOnBlur="false"  />
              <ErrorMessage name="file" class="text-danger" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Import</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';
import CustomSelect from '@/components-gc/CustomSelect.vue';

export default {
  data() {
    return {
      file: null,
      optionsType: ['Sensor', 'Gateway'],
      selectedType: this.type,
    }
  },
  props: {
    type: {
      type: String,
      default: 'Gateway'
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    CustomSelect,
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/device/import`;

      const formData = new FormData();
      formData.append('type', this.selectedType.toLowerCase());
      formData.append('import_file', this.file);

      this.axios.post(api, formData)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$emit('update', this.selectedType);
            this.$emit('hide');
            this.$toastSuccess('Added successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
  },
  emits: ['update', 'hide']
}
</script>