<template>
  <Breadcrumbs title="Information" />
  <div class="container-fluid">
    <div class="row g-3">
      <div class="col-12">
         <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center 
        justify-content-between mb-3 gap-3">
          <div class="d-flex gap-3 flex-column flex-md-row flex-shrink-0">
            <div class="select-wrap flex-shrink-0">
              <CustomSelect
                v-model="listRequest.status"
                :options="options.status"
                field="status"
                placeholder="Status"
              />
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
            <a href="javascript:void(0)" class="align-self-center clear-btn" @click.prevent="clearAll">Clear all</a>          </div>
          <button type="button" @click="showModal('addNew')" class="btn btn-primary text-nowrap ">Add New</button>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Index</th>
                  <th scope="col">Version</th>
                  <th scope="col">Note</th>
                  <th scope="col" @click="sortList('status')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Status</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('publish_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Publish at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('publish_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('publish_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('created_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Created at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody v-if="list.length > 0">
                <tr v-for="item in list" :key="item.id">
                  <td>#{{ item.id }}</td>
                  <td>{{ item.version }}</td>
                  <td>
                    <p class="single-ellipsis td-width mb-0">{{ item.note }}</p>
                  </td>
                  <td>
                    <span :class="{ 'txt-primary': item.status === 'published' }">{{ $capitalizeFirst(item.status) }}</span>
                  </td>
                  <td>{{ item.publish_at }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <router-link 
                      :to="{ name: 'informationDetail',  params: { informationId: item.id },
                        query: { 
                          status: listRequest.status,
                          page: listRequest.page,
                        }
                      }" 
                      class="me-3">
                       <i class="fa fa-file-text-o fs-5" aria-hidden="true"></i>
                    </router-link>
                    <a href="javascript:;" @click.prevent="confirmDelete(item.id)">
                      <i class="fa fa-trash-o fs-4" aria-hidden="true"></i>
                    </a>
                    <a v-if="item.status === 'waiting'" href="javascript:;" class="ms-3" @click.prevent="confirmPublish(item.id)">
                      <i class="fa fa-paper-plane-o fs-5" aria-hidden="true"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7" class="text-center">
                    {{ listRequest.search ? 'No matching records found' : 'No data available in table' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
  <addNew v-if="shown.addNew" ref="addNew" @hide="hideModal('addNew')" @update="getList" />
  <deleteModal v-if="shown.deleteModal" ref="deleteModal" :type="'information'"  @update="handleDelete" @hide="hideModal('deleteModal')" />
  <publishModal v-if="shown.publishModal" ref="publishModal"  @update="handlePublish" @hide="hideModal('publishModal')" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import addNew from '@/components-gc/modal/setting/information/addNew.vue';
import deleteModal from '@/components-gc/modal/delete.vue';
import publishModal from '@/components-gc/modal/publish.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';
import CustomSelect from '@/components-gc/CustomSelect.vue';

export default {
  data() {
    return {
      modals: {},
      list: [],
      listRequest: {
        status: '',
        page: 1,
        limit: 10,
        order_by: "",
        order: "",
      },
      total: 0,
      options: {
        status: ['All', 'Waiting', 'Published'],
      },
      shown: {
        addNew: false,
        deleteModal: false,
        publishModal: false,
      },
      previousListRequest: {},
      informationId: null
    };
  },
  components: {
    addNew,
    deleteModal,
    publishModal,
    Pagination,
    CustomSelect
  },
  mounted() {
    if (this.$route.query) {
      this.listRequest.status = this.$route.query.status || '';
      this.listRequest.page = parseInt(this.$route.query.page) || 1;
    }
    this.getList();
  },
  methods: {
    getList() {
      const params = { ...this.listRequest };
      Object.keys(params).forEach(key => {
        if (params[key] === 'All') {
          params[key] = '';
        }
        if(params[key] !== null && typeof params[key] === 'string') {
          params[key] = params[key].toLowerCase();
        }
      });
      
      const api = `${process.env.VUE_APP_PATH}/information/list`;
      this.axios.post(api, params)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.list = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    statusChange(val) {
      this.listRequest.status = val;
    },
    clearAll() {
      const isAllCleared = !this.listRequest.status;
      if (!isAllCleared) {
        this.listRequest = { ...this.listRequest, status: '' };
        this.listRequest.page = 1;
        this.getList();
      }
    },
    handleSearch() {
      if (JSON.stringify(this.listRequest) !== JSON.stringify(this.previousListRequest)) {
        this.previousListRequest = { ...this.listRequest };
        this.listRequest.page = 1;
        this.getList();
      }
    },
    confirmDelete(id) {
      this.informationId = id;
      this.showModal('deleteModal');
    },
    confirmPublish(id) {
      this.informationId = id;
      this.showModal('publishModal');
    },
    handleDelete() {
      this.hideModal('deleteModal');

      const api = `${process.env.VUE_APP_PATH}/information/delete`;
      const payload = {
        id: this.informationId
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('Deleted successfully');
          this.hideModal('deleteModal');
          this.listRequest.page = 1;
          this.getList();
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    handlePublish() {
      const api = `${process.env.VUE_APP_PATH}/information/directly_publish`;
      const payload = {
        id: this.informationId
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('Published successfully');
          this.hideModal('publishModal');
          this.getList();
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
};
</script>
