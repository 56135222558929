<template>
  <Breadcrumbs title="Terms of Service"/>
  <div class="container-fluid">
    <div class="d-flex align-items-center gap-1 mb-4">
      <router-link :to="{ name: 'termsOfService', query: this.$route.query }" class="link-dark">
        <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
      </router-link>
      <h5 class="mb-0">Ver {{ termsDetail.version }}</h5>
    </div>
    <div class="row g-3">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <p class="text-secondary fs-6 mb-0">Ver {{ termsDetail.version }}</p>
              <div v-if="termsDetail.status !== 'published'" class="fw-semibold">
                <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                <a href="javascript:;" class="text-primary" @click="showModal('editNote')">Edit</a>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-md-6">
                <div v-if="termsDetail.creator" class="d-flex align-items-center gap-2">
                  <img class="img-50 rounded-circle" :src="termsDetail.creator.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                  <div>
                    <h6>{{ termsDetail.creator.first_name }} {{ termsDetail.creator.last_name }}</h6>
                    <p class="mb-0 text-secondary">{{ termsDetail.creator.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <h6>Publish at</h6>
                <p class="mb-0 text-secondary">{{ termsDetail.publish_at }}</p>
              </div>
              <div class="col-md-3">
                <h6>Created at</h6>
                <p class="mb-0 text-secondary">{{ termsDetail.created_at }}</p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p class="fs-6">Note</p>
            <p class="text-secondary mb-0">{{ termsDetail.note }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h5 class="text-secondary">Contents</h5>
              <div v-if="termsDetail.status !== 'published'" class="fw-semibold">
                <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                <a href="javascript:;" class="text-primary" @click="showModal('editContent')">Edit</a>
              </div>
            </div>
            <div class="content-box mb-0" v-html="termsDetail.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <editNote ref="editNote" v-if="shown.editNote" :detail="termsDetail"
  @hide="hideModal('editNote')" @update="getTermsDetail()"></editNote>
  <editContent ref="editContent" v-if="shown.editContent" :detail="termsDetail"
  @hide="hideModal('editContent')" @update="getTermsDetail()"></editContent>
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import editNote from '@/components-gc/modal/setting/termsOfService/editNote.vue';
import editContent from '@/components-gc/modal/setting/termsOfService/editContent.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      termsDetail: {},
      shown: {
        editNote: false,
        editContent: false,
      },
      modals: {},
    };
  },
  components: {
    editNote,
    editContent,
  },
  mounted() {
    this.getTermsDetail();
  },
  methods: {
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
    getTermsDetail() {
      const api = `${process.env.VUE_APP_PATH}/terms_of_service/detail`;
      this.axios.post(api, { id: this.$route.params.termsId })
        .then((res) => {
          const msg = checkStatusCode(res.data.status_code);
          if (res.data.status_code === 'SYSTEM_1000') {
            this.termsDetail = res.data.detail;
            } else if(res.data.status_code === 'SYSTEM_0008') {
              this.$toastWarning(`${msg} You will be redirected to the list.`);
              setTimeout(() => {
                this.$router.push({ name: 'termsOfService' });
              }, 4000);
            } else {
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
  },
};
</script>
