<template>
  <div class="modal fade" id="editNoteModal" tabindex="-1" aria-labelledby="editNoteLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form ref="form" @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="date" class="col-form-label fw-semibold">Publish Date and Time</label>
                <Field name="date" :rules="{ required: true }" v-slot="{ field }" id="date">
                  <Datepicker1 class="datepicker-here form-control digits py-0" placeholder="Date"
                    v-model="localDetail.publish_at" v-bind="field" data-language="en" :min-date="new Date()"></Datepicker1>
                  <ErrorMessage name="date" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-md-6 mb-3">
                <label for="type" class="col-form-label fw-semibold">Version</label>
                <p class="mb-0 text-secondary">{{ localDetail.version }}</p>
              </div>
              <div class="col-md-6 mb-3">
                <label for="type" class="col-form-label fw-semibold">Update Type</label>
                <Field name="type" :rules="{ required: true }" v-slot="{ field }">
                  <CustomSelect 
                    v-model="localDetail.update_type"
                    :options="options.type" 
                    :searchable="true" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    :allow-empty="false"
                    placeholder="type" 
                    v-bind="field"
                  ></CustomSelect>
                  <ErrorMessage name="type" class="text-danger"></ErrorMessage>
                </Field>
              </div>
               <div class="col-md-12">
                <label for="note" class="col-form-label fw-semibold">Realease Note</label>
                <Field v-model="localDetail.note" name="note" as="textarea" class="form-control" placeholder="Add text" id="note" />
                <ErrorMessage name="note" class="text-danger" />
              </div>

            </div>
          </div>
          <div class="modal-footer justify-content-end">
              <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Datepicker1 from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { checkStatusCode } from '@/methods-gc/statusCode';
import CustomSelect from '@/components-gc/CustomSelect.vue';

export default {
  data() {
    return {
      localDetail: { ...this.detail },
      editorOption: {
        placeholder: 'content',
      },
      options: {
        type: [
          'Force Update', 
          'Important Update',
          'General Update', 
        ],
      },

    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.form.setFieldValue('date', this.localDetail.publish_at);
      this.$refs.form.setFieldValue('type', this.localDetail.update_type);
    });
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker1,
    CustomSelect,
  },
  methods: {
    getOptionVal(option) {
      switch(option) {
        case 'Force Update':
          return 'c'
        case 'Important Update':
          return 'h'
        case 'General Update':
          return 'l'
        default:
          return ''
      }
    },
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/update/update`;
      const payload = {
        id: this.$route.params.updateId,
        publish_at: this.localDetail.publish_at,
        note: this.localDetail.note,
        update_type: this.getOptionVal(this.localDetail.update_type),
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update');
          this.$emit('hide');
          this.$toastSuccess('Updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
  props: ['detail'],
  emits: ['hide', 'update'],
}
</script>